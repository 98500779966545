<template>
  <v-row>
    <loading :active.sync="loading"></loading>
    <v-card class="pa-6 mt-n8" width="100%" flat>
      <v-container fluid>
        <v-row class="mt-5">
          <v-col cols="12" md="9" sm="9">
            <v-btn icon class="mt-n3" @click="cancel()">
              <v-icon> mdi-less-than </v-icon>
            </v-btn>
            <span class="text-h4" style="font-weight: 600">
              เพิ่มคณาจารย์
            </span>
          </v-col>
          <v-col cols="12" md="3" sm="3" class="text-right">
            <v-btn
              @click="submit()"
              color="#2AB3A3"
              dark
              elevation="0"
              style="padding: 12px 16px; gap: 8px; width: 160px; height: 48px"
            >
              บันทึก
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-tabs align-with-title v-model="e1">
      <v-col
        cols="2"
        md="3"
        sm="3"
        :style="
          e1 == 0
            ? 'border-bottom: 3px solid #2ab3a3'
            : 'border-bottom: 1px solid #2ab3a3'
        "
      >
        <v-tab class="textResponsive1">
          <span><b>เกี่ยวกับอาจารย์</b></span>
        </v-tab>
      </v-col>
      <v-col
        cols="2"
        md="3"
        sm="3"
        :style="
          e1 == 1
            ? 'border-bottom: 3px solid #2ab3a3'
            : 'border-bottom: 1px solid #2ab3a3'
        "
      >
        <v-tab class="textResponsive1">
          <span><b>ผลงานวิชาการ</b></span>
        </v-tab>
      </v-col>
      <v-col
        cols="2"
        md="3"
        sm="3"
        :style="
          e1 == 2
            ? 'border-bottom: 3px solid #2ab3a3'
            : 'border-bottom: 1px solid #2ab3a3'
        "
      >
        <v-tab class="textResponsive1">
          <span><b>ข้อมูลอื่น ๆ</b></span>
        </v-tab>
      </v-col>
      <v-col cols="6" md="3" sm="3" style="border-bottom: 1px solid #2ab3a3">
        <v-tab class="textResponsive"> </v-tab>
      </v-col>

      <v-tabs-slider color="#2AB3A3"> </v-tabs-slider>
      <v-tab-item>
        <v-card class="pa-6" width="100%" flat color="#f5f5f5">
          <v-card class="pa-6" width="100%" flat>
            <v-form
              ref="createForm"
              v-model="checkValidate"
              :lazy-validation="lazy"
            >
              <v-row>
                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">ชื่ออาจารย์ (TH)</span>
                  <span style="color: red">*</span>
                  <v-text-field
                    hide-details
                    v-model="form.name_th"
                    placeholder="ระบุชื่ออาจารย์ (TH)"
                    dense
                    outlined
                    :rules="rules.required"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">ชื่ออาจารย์ (EN)</span>
                  <v-text-field
                    hide-details
                    v-model="form.name_en"
                    placeholder="ระบุชื่ออาจารย์ (EN)"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">ตำแหน่ง (TH)</span>
                  <span style="color: red">*</span>
                  <v-text-field
                    hide-details
                    v-model="form.position_th"
                    placeholder="ระบุตำแหน่ง (TH)"
                    dense
                    outlined
                    :rules="rules.required"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">ตำแหน่ง (EN)</span>
                  <v-text-field
                    hide-details
                    v-model="form.position_en"
                    placeholder="ระบุตำแหน่ง (EN)"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">เบอร์โทร</span>
                  <span style="color: red">*</span>
                  <v-text-field
                    hide-details
                    v-model="form.tel"
                    placeholder="เบอร์โทร"
                    dense
                    outlined
                    :rules="rules.required"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">อีเมล</span>
                  <span style="color: red">*</span>
                  <v-text-field
                    hide-details
                    v-model="form.email"
                    placeholder="อีเมล"
                    dense
                    outlined
                    :rules="rules.emailRules"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="12" sm="12"
                  ><span
                    class="pt-2"
                    style="
                      width: 170px;
                      height: 26px;
                      font-weight: 500;
                      font-size: 16px;
                      line-height: 26px;
                    "
                    ><b>รูปอาจารย์</b></span
                  >
                  <span style="color: red">*</span>
                </v-col>
                <v-row class="ml-0">
                  <v-col cols="12" md="2" sm="2" xs="2" v-if="showImage">
                    <v-img
                      :src="showImage"
                      max-width="150px"
                      max-height="150px"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" md="2" sm="2" xs="2" v-if="!showImage">
                    <v-img
                      src="../../assets/noFile.png"
                      max-width="150px"
                      max-height="150px"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" md="10">
                    <span>{{ namePic }}</span>
                    <br />
                    <span>ขนาดรูปที่แนะนำ : 1200 x 1200px</span>
                    <br />
                    <br />
                    <br />
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      id="file"
                      ref="q_1_1"
                      v-on:change="submitfileMain('q_1_1')"
                    />
                  </v-col>
                </v-row>

                <v-col cols="12" md="12" sm="12"
                  ><span
                    class="pt-2"
                    style="
                      width: 170px;
                      height: 26px;
                      font-weight: 500;
                      font-size: 16px;
                      line-height: 26px;
                    "
                    ><b>รูปอาจารย์ (ภาพใหญ่)</b></span
                  >
                  <span style="color: red">*</span>
                </v-col>

                <v-row class="ml-0">
                  <v-col cols="12" md="3" sm="3" xs="3" v-if="showImage1">
                    <v-img
                      :src="showImage1"
                      max-width="250px"
                      max-height="250px"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" md="3" sm="3" xs="3" v-if="!showImage1">
                    <v-img
                      src="../../assets/noFile.png"
                      max-width="250px"
                      max-height="250px"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" md="9">
                    <span>{{ namePic1 }}</span>
                    <br />
                    <br />
                    <br />
                    <span>ขนาดรูปที่แนะนำ : 1500 x 780px</span>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      id="file"
                      ref="q_1_2"
                      v-on:change="submitfileMain('q_1_2')"
                    />
                  </v-col>
                </v-row>

                <!-- <v-col cols="12" md="12" sm="12"
                  ><span class="pt-2 text-h6">เกี่ยวกับอาจารย์</span>
                </v-col>
                <v-col cols="12" md="3" sm="3"
                  ><span class="pt-2">ประวัติการศึกษา (TH)</span>
                  <span style="color: red">*</span></v-col
                >
                <v-col cols="12" md="7" sm="7">
                  <v-textarea
                    v-model="educational_record_th"
                    dense
                    outlined
                    auto-grow
                    :rules="rules.required"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="3" sm="3"
                  ><span class="pt-2">ประวัติการศึกษา (EN)</span>
                  <span style="color: red">*</span></v-col
                >
                <v-col cols="12" md="7" sm="7">
                  <v-textarea
                    v-model="educational_record_en"
                    dense
                    outlined
                    auto-grow
                    :rules="rules.required"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="3" sm="3"
                  ><span class="pt-2">ความเชี่ยวชาญและความสนใจ (TH)</span>
                  <span style="color: red">*</span></v-col
                >
                <v-col cols="12" md="7" sm="7">
                  <v-textarea
                    v-model="expertise_and_interest_th"
                    dense
                    outlined
                    auto-grow
                    :rules="rules.required"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="3" sm="3"
                  ><span class="pt-2">ความเชี่ยวชาญและความสนใจ (EN)</span>
                  <span style="color: red">*</span></v-col
                >
                <v-col cols="12" md="7" sm="7">
                  <v-textarea
                    v-model="expertise_and_interest_en"
                    dense
                    outlined
                    auto-grow
                    :rules="rules.required"
                  ></v-textarea>
                </v-col>

                <v-col cols="12" md="3" sm="3"
                  ><span class="pt-2">ตำแหน่งภายนอกสถาบัน (TH)</span>
                </v-col>
                <v-col cols="12" md="7" sm="7">
                  <v-text-field
                hide-details
                    v-model="off_campus_position_th"
                    placeholder="ตำแหน่งภายนอกสถาบัน (TH)"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="3"
                  ><span class="pt-2">ตำแหน่งภายนอกสถาบัน (EN)</span>
                </v-col>
                <v-col cols="12" md="7" sm="7">
                  <v-text-field
                hide-details
                    v-model="off_campus_position_en"
                    placeholder="ตำแหน่งภายนอกสถาบัน (EN)"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3" sm="3"
                  ><span class="pt-2">รางวัลและเกียรติยศ (TH)</span>
                </v-col>
                <v-col cols="12" md="7" sm="7">
                  <v-textarea
                    v-model="awards_and_honors_th"
                    dense
                    outlined
                    auto-grow
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="3" sm="3"
                  ><span class="pt-2">รางวัลและเกียรติยศ (EN)</span>
                </v-col>
                <v-col cols="12" md="7" sm="7">
                  <v-textarea
                    v-model="awards_and_honors_en"
                    dense
                    outlined
                    auto-grow
                  ></v-textarea>
                </v-col>

                <v-col cols="12" md="12" sm="12"
                  ><span class="pt-2 text-h6">ผลงานวิชาการ</span>
                </v-col>
                <v-col cols="12" md="3" sm="3"
                  ><span class="pt-2"
                    >รายชื่อวิทยานิพนธ์/การศึกษาค้นขว้าอิสระ (TH)</span
                  >
                </v-col>
                <v-col cols="12" md="7" sm="7">
                  <v-textarea
                    v-model="list_of_thesis_th"
                    dense
                    outlined
                    auto-grow
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="3" sm="3"
                  ><span class="pt-2"
                    >รายชื่อวิทยานิพนธ์/การศึกษาค้นขว้าอิสระ (EN)</span
                  >
                </v-col>
                <v-col cols="12" md="7" sm="7">
                  <v-textarea
                    v-model="list_of_thesis_en"
                    dense
                    outlined
                    auto-grow
                  ></v-textarea>
                </v-col>

                <v-col cols="12" md="3" sm="3"
                  ><span class="pt-2"
                    >ผลงานวิจัยที่ได้รับการตีพิมพ์เผยแพร่ (TH)</span
                  >
                </v-col>
                <v-col cols="12" md="7" sm="7">
                  <v-textarea
                    v-model="research_results_th"
                    dense
                    outlined
                    auto-grow
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="3" sm="3"
                  ><span class="pt-2"
                    >ผลงานวิจัยที่ได้รับการตีพิมพ์เผยแพร่ (EN)</span
                  >
                </v-col>
                <v-col cols="12" md="7" sm="7">
                  <v-textarea
                    v-model="research_results_en"
                    dense
                    outlined
                    auto-grow
                  ></v-textarea>
                </v-col>

                <v-col cols="12" md="3" sm="3"
                  ><span class="pt-2"
                    >โครงการวิจัย (ที่ได้รับเงินสนับสนุน) (TH)</span
                  >
                </v-col>
                <v-col cols="12" md="7" sm="7">
                  <v-textarea
                    v-model="research_project_th"
                    dense
                    outlined
                    auto-grow
                  ></v-textarea>
                </v-col>

                <v-col cols="12" md="3" sm="3"
                  ><span class="pt-2"
                    >โครงการวิจัย (ที่ได้รับเงินสนับสนุน) (EN)</span
                  >
                </v-col>
                <v-col cols="12" md="7" sm="7">
                  <v-textarea
                    v-model="research_project_en"
                    dense
                    outlined
                    auto-grow
                  ></v-textarea>
                </v-col>

                <v-col cols="12" md="3" sm="3"
                  ><span class="pt-2">ข้อมูลอื่นๆ (Other detail)</span>
                </v-col>
                <v-col cols="12" md="7" sm="7">
                  <v-textarea
                    v-model="other_detail"
                    dense
                    outlined
                    auto-grow
                  ></v-textarea>
                </v-col> -->
              </v-row>

              <!-- <v-row align="center" justify="end">
                <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
                <v-btn @click="submit('q_1_1')" dark color="#2AB3A3"
                  >บันทึก</v-btn
                >
              </v-row> -->
            </v-form>
          </v-card>
        </v-card>

        <v-card class="pa-6" width="100%" flat color="#f5f5f5">
          <v-card class="pa-6" width="100%" flat>
            <v-tabs align-with-title v-model="e2">
              <v-col
                cols="2"
                md="3"
                sm="3"
                :style="
                  e2 == 0
                    ? 'border-bottom: 3px solid #2ab3a3'
                    : 'border-bottom: 1px solid #2ab3a3'
                "
              >
                <v-tab class="textResponsive1">
                  <span><b>ประวัติการศึกษา</b></span>
                </v-tab>
              </v-col>
              <v-col
                cols="2"
                md="3"
                sm="3"
                :style="
                  e2 == 1
                    ? 'border-bottom: 3px solid #2ab3a3'
                    : 'border-bottom: 1px solid #2ab3a3'
                "
              >
                <v-tab class="textResponsive1">
                  <span><b>ความเชี่ยวชาญและความสนใจ</b></span>
                </v-tab>
              </v-col>
              <v-col
                cols="2"
                md="3"
                sm="3"
                :style="
                  e2 == 2
                    ? 'border-bottom: 3px solid #2ab3a3'
                    : 'border-bottom: 1px solid #2ab3a3'
                "
              >
                <v-tab class="textResponsive1">
                  <span><b>ตำแหน่งภายนอกสถาบัน</b></span>
                </v-tab>
              </v-col>
              <v-col
                cols="2"
                md="3"
                sm="3"
                :style="
                  e2 == 3
                    ? 'border-bottom: 3px solid #2ab3a3'
                    : 'border-bottom: 1px solid #2ab3a3'
                "
              >
                <v-tab class="textResponsive1">
                  <span><b>รางวัลและเกียรติยศ</b></span>
                </v-tab>
              </v-col>
              <v-col
                cols="4"
                md="0"
                sm="0"
                style="border-bottom: 1px solid #2ab3a3"
              >
                <v-tab class="textResponsive1"> </v-tab>
              </v-col>
              <v-tabs-slider color="#2AB3A3"> </v-tabs-slider>

              <v-tab-item>
                <v-row class="pa-6">
                  <v-col cols="12" md="12" sm="12"
                    ><span class="pt-2">เนื้อหา (TH)</span>
                    <quill-editor
                      class="quill700"
                      v-model="form.educational_record_th"
                      :content="content"
                      :options="editorOption"
                      @change="onEditorChange($event)"
                    />
                  </v-col>

                  <v-col cols="12" md="12" sm="12"
                    ><span class="pt-2">เนื้อหา (EN)</span>
                    <quill-editor
                      class="quill700"
                      v-model="form.educational_record_en"
                      :content="content1"
                      :options="editorOption"
                      @change="onEditorChange1($event)"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <v-row class="pa-6">
                  <v-col cols="12" md="12" sm="12"
                    ><span class="pt-2">เนื้อหา (TH)</span>
                    <quill-editor
                      class="quill700"
                      v-model="form.expertise_and_interest_th"
                      :content="content2"
                      :options="editorOption"
                      @change="onEditorChange2($event)"
                    />
                  </v-col>

                  <v-col cols="12" md="12" sm="12"
                    ><span class="pt-2">เนื้อหา (EN)</span>
                    <quill-editor
                      class="quill700"
                      v-model="form.expertise_and_interest_en"
                      :content="content3"
                      :options="editorOption"
                      @change="onEditorChange3($event)"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <v-row class="pa-6">
                  <v-col cols="12" md="12" sm="12"
                    ><span class="pt-2">เนื้อหา (TH)</span>
                    <quill-editor
                      class="quill700"
                      v-model="form.off_campus_position_th"
                      :content="content4"
                      :options="editorOption"
                      @change="onEditorChange4($event)"
                    />
                  </v-col>

                  <v-col cols="12" md="12" sm="12"
                    ><span class="pt-2">เนื้อหา (EN)</span>
                    <quill-editor
                      class="quill700"
                      v-model="form.off_campus_position_en"
                      :content="content5"
                      :options="editorOption"
                      @change="onEditorChange5($event)"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <v-row class="pa-6">
                  <v-col cols="12" md="12" sm="12"
                    ><span class="pt-2">เนื้อหา (TH)</span>
                    <quill-editor
                      class="quill700"
                      v-model="form.awards_and_honors_th"
                      :content="content6"
                      :options="editorOption"
                      @change="onEditorChange6($event)"
                    />
                  </v-col>

                  <v-col cols="12" md="12" sm="12"
                    ><span class="pt-2">เนื้อหา (EN)</span>
                    <quill-editor
                      class="quill700"
                      v-model="form.awards_and_honors_en"
                      :content="content7"
                      :options="editorOption"
                      @change="onEditorChange7($event)"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card class="pa-6" width="100%" flat color="#f5f5f5">
          <v-card class="pa-6" width="100%" flat>
            <v-tabs align-with-title v-model="e3">
              <v-col
                cols="3"
                md="4"
                sm="4"
                :style="
                  e3 == 0
                    ? 'border-bottom: 3px solid #2ab3a3'
                    : 'border-bottom: 1px solid #2ab3a3'
                "
              >
                <v-tab class="textResponsive1">
                  <span><b>รายชื่อวิทยานิพนธ์/การศึกษาค้นคว้าอิสระ</b></span>
                </v-tab>
              </v-col>
              <v-col
                cols="3"
                md="4"
                sm="4"
                :style="
                  e3 == 1
                    ? 'border-bottom: 3px solid #2ab3a3'
                    : 'border-bottom: 1px solid #2ab3a3'
                "
              >
                <v-tab class="textResponsive1">
                  <span><b>ผลงานวิจัยที่ได้รับการตีพิมพ์เผยแพร่</b></span>
                </v-tab>
              </v-col>
              <v-col
                cols="3"
                md="4"
                sm="4"
                :style="
                  e3 == 2
                    ? 'border-bottom: 3px solid #2ab3a3'
                    : 'border-bottom: 1px solid #2ab3a3'
                "
              >
                <v-tab class="textResponsive1">
                  <span><b>โครงการวิจัย (ที่ได้รับเงินสนับสนุน)</b></span>
                </v-tab>
              </v-col>
              <v-tabs-slider color="#2AB3A3"> </v-tabs-slider>

              <v-tab-item>
                <v-row class="pa-6">
                  <v-col cols="12" md="12" sm="12"
                    ><span class="pt-2">เนื้อหา (TH)</span>
                    <quill-editor
                      class="quill700"
                      v-model="form.list_of_thesis_th"
                      :content="content8"
                      :options="editorOption"
                      @change="onEditorChange8($event)"
                    />
                  </v-col>

                  <v-col cols="12" md="12" sm="12"
                    ><span class="pt-2">เนื้อหา (EN)</span>
                    <quill-editor
                      class="quill700"
                      v-model="form.list_of_thesis_en"
                      :content="content9"
                      :options="editorOption"
                      @change="onEditorChange9($event)"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <v-row class="pa-6">
                  <v-col cols="12" md="12" sm="12"
                    ><span class="pt-2">เนื้อหา (TH)</span>
                    <quill-editor
                      class="quill700"
                      v-model="form.research_results_th"
                      :content="content10"
                      :options="editorOption"
                      @change="onEditorChange10($event)"
                    />
                  </v-col>

                  <v-col cols="12" md="12" sm="12"
                    ><span class="pt-2">เนื้อหา (EN)</span>
                    <quill-editor
                      class="quill700"
                      v-model="form.research_results_en"
                      :content="content11"
                      :options="editorOption"
                      @change="onEditorChange11($event)"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <v-row class="pa-6">
                  <v-col cols="12" md="12" sm="12"
                    ><span class="pt-2">เนื้อหา (TH)</span>
                    <quill-editor
                      class="quill700"
                      v-model="form.research_project_th"
                      :content="content12"
                      :options="editorOption"
                      @change="onEditorChange12($event)"
                    />
                  </v-col>

                  <v-col cols="12" md="12" sm="12"
                    ><span class="pt-2">เนื้อหา (EN)</span>
                    <quill-editor
                      class="quill700"
                      v-model="form.research_project_en"
                      :content="content13"
                      :options="editorOption"
                      @change="onEditorChange13($event)"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card class="pa-6" width="100%" flat color="#f5f5f5">
          <v-card class="pa-6" width="100%" flat>
            <v-row class="pa-6">
              <v-col cols="12" md="12" sm="12"
                ><span class="pt-2">เนื้อหา (TH)</span>
                <quill-editor
                  class="quill700"
                  v-model="form.other_detail_th"
                  :content="content14"
                  :options="editorOption"
                  @change="onEditorChange14($event)"
                />
              </v-col>

              <v-col cols="12" md="12" sm="12"
                ><span class="pt-2">เนื้อหา (EN)</span>
                <quill-editor
                  class="quill700"
                  v-model="form.other_detail_en"
                  :content="content15"
                  :options="editorOption"
                  @change="onEditorChange15($event)"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
import { VueEditor } from "vue2-editor";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
    VueEditor,
  },
  data() {
    return {
      e1: 0,
      e2: 0,
      e3: 0,
      namePic: "",
      namePic1: "",
      form: {
        name_en: null,
        name_th: null,
        position_en: null,
        position_th: null,
        tel: "",
        email: null,

        educational_record_th: "",
        educational_record_en: "",
        expertise_and_interest_th: "",
        expertise_and_interest_en: "",
        off_campus_position_th: "",
        off_campus_position_en: "",
        awards_and_honors_th: "",
        awards_and_honors_en: "",

        list_of_thesis_th: "",
        list_of_thesis_en: "",
        research_results_th: "",
        research_results_en: "",
        research_project_th: "",
        research_project_en: "",

        other_detail_th: "",
        other_detail_en: "",
      },

      images_url_profile: null,
      images_url_profile_big: null,

      educational_record_en: null,
      educational_record_th: null,
      expertise_and_interest_en: null,
      expertise_and_interest_th: null,
      off_campus_position_en: null,
      off_campus_position_th: null,
      awards_and_honors_en: null,
      awards_and_honors_th: null,
      list_of_thesis_en: null,
      list_of_thesis_th: null,
      research_results_en: null,
      research_results_th: null,
      research_project_en: null,
      research_project_th: null,
      other_detail: null,
      types: 1,

      content: "",
      content1: "",
      content2: "",
      content3: "",
      content4: "",
      content5: "",
      content6: "",
      content7: "",
      content8: "",
      content9: "",
      content10: "",
      content11: "",
      content12: "",
      content13: "",
      content14: "",
      content15: "",
      keyImg: "",
      activeFlag: true,

      editorOption: {
        placeholder: "ระบุข้อมูล",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      pic1: "",
      picture_1: "",
      pic1URL: "",
      showImage: "",
      showImage1: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        tel: [
          (val) =>
            (val.length <= 10 && val.length >= 9) ||
            "Phone Number must be 9-10 charactors only.",
          (val) => !!val || "Please enter your phone number.",
        ],
      },
      checkValidate: true,
      lazy: false,
      user: "",
      linkUrl: "",
      productId: 0,
      items: [],
      category: "",
      itemsCategory: [],
      type: "CONTENT",
      category: "",
      desc: "",
      itemsType: [],
      brand: "",
      itemsBrand: [],
      img: "",
      loading: false,
      content_url: "",
      loading: false,
      file: [],
      video_url: "",
      itemTypes: [
        {
          name: "CONTENT",
          val: "CONTENT",
        },
        {
          name: "VIDEO",
          val: "VIDEO",
        },
      ],
      itemCategory: [
        {
          name: "แนะนำ",
          val: "SUGGEST",
        },
        {
          name: "ครอบครัว",
          val: "HOME2",
        },
        {
          name: "งานวิจัย",
          val: "RESEARCH",
        },
      ],
      levelItems: [],
      FacultyItems: [],
    };
  },
  watch: {
    productId(val) {
      if (val) {
        console.log("productId", val);
      }
    },
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {},
  methods: {
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    onEditorChange1({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content1 = html;
    },
    onEditorChange2({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content2 = html;
    },
    onEditorChange3({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content3 = html;
    },
    onEditorChange4({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content4 = html;
    },
    onEditorChange5({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content5 = html;
    },
    onEditorChange6({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content6 = html;
    },
    onEditorChange7({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content7 = html;
    },
    onEditorChange8({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content8 = html;
    },
    onEditorChange9({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content9 = html;
    },
    onEditorChange10({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content10 = html;
    },
    onEditorChange11({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content11 = html;
    },
    onEditorChange12({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content12 = html;
    },
    onEditorChange13({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content13 = html;
    },
    onEditorChange14({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content12 = html;
    },
    onEditorChange15({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content13 = html;
    },

    showPicture1(e) {
      // console.log('index',index);
      console.log("55");
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageTeacherDetails");
    },
    async submitfileMain(questionNo) {
      if (this.$refs[questionNo].files[0]) {
        let formData = new FormData();
        formData.append("files", this.$refs[questionNo].files[0]);
        formData.append("filename", this.$refs[questionNo].files[0].name);
        formData.append("type", "uploadfile");
        // formData.append("userId", this.userId);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        if (questionNo == "q_1_1") {
          this.images_url_profile = response.data.data.path;
          this.showImage = response.data.data.path;
        }
        if (questionNo == "q_1_2") {
          this.images_url_profile_big = response.data.data.path;
          this.showImage1 = response.data.data.path;
        }

        console.log("SUCCESS!!");
        console.log("response", response);
      }
    },

    async submit(pic) {
      console.log(this.file, "f");
      if (this.$refs.createForm.validate(true)) {
        // if (this.type == "CONTENT") {
        // await this.submitfileMain(pic);
        // console.log("pic", pic);
        // }
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          images_url_profile: this.images_url_profile,
          images_url_profile_big: this.images_url_profile_big,
          types: this.types,
          ...this.form,
          // name_en: this.name_en,
          // name_th: this.name_th,
          // position_en: this.position_en,
          // position_th: this.position_th,
          // tel: this.tel,
          // email: this.email,
          // educational_record_en: this.educational_record_en,
          // educational_record_th: this.educational_record_th,
          // expertise_and_interest_en: this.expertise_and_interest_en,
          // expertise_and_interest_th: this.expertise_and_interest_th,
          // off_campus_position_en: this.off_campus_position_en,
          // off_campus_position_th: this.off_campus_position_th,
          // awards_and_honors_en: this.awards_and_honors_en,
          // awards_and_honors_th: this.awards_and_honors_th,
          // list_of_thesis_en: this.list_of_thesis_en,
          // list_of_thesis_th: this.list_of_thesis_th,
          // research_results_en: this.research_results_en,
          // research_results_th: this.research_results_th,
          // research_project_en: this.research_project_en,
          // research_project_th: this.research_project_th,
          // other_detail: this.other_detail,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/teacherDetails`,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างรายละเอียดอาจารย์สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageTeacherDetails");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>
